.input-fields {
  font-family: $font-body;
  font-size: $font-size-S;
  color: $color-grey-3;
  line-height: inherit;
  opacity: 1;
  background-image: linear-gradient(
    to right,
    rgba($color-black, 0.6),
    rgba($color-white, 0.4)
  );
  border: rgba($color-white, 0.4) 1px solid;
  background-size: 200%;
  @include transition-time;
  margin-bottom: $size-XL;
  &--text {
    padding: $size-S;
    max-width: 100%;
    &:hover {
      border: rgba($color-white, 0.6) 1px solid;
    }
  }
  &--textarea {
    padding: $size-S;
    max-width: 40rem;
    min-width: 40rem;
    min-height: 9rem;
    &:hover {
      border: rgba($color-white, 0.6) 1px solid;
    }

    @media only screen and (max-width: $bp-smallest) {
      max-width: 100%;
      min-width: 25rem;
      min-height: 5rem;
    }
    @media only screen and (max-width: $bp-tiny) {
      max-width: 100%;
      min-width: 18rem;
      min-height: 5rem;
    }
  }
}

input:focus {
  background-size: 100%;
  border: rgba($color-white, 0.8) 1px solid;
}
textarea:focus {
  background-size: 100%;
  border: rgba($color-white, 0.8) 1px solid;
}

.form-test {
  display: flex;
  flex-direction: column;

  padding: 5rem;
}

.error-field {
  border: rgba($color-primary, 0.9) 1px solid !important;
}
