.home {
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  width: 100%;
  height: 100%;
  &__title {
    grid-column: 1/-1;
    grid-row: 3 / span 4;
    justify-self: center;
    text-align: center;
  }

  &__widgets {
    position: relative;
    z-index: 15;
    grid-column: col-start 20 / full-end;
    grid-row: row-start 3 / span 2;
    align-self: end;
    justify-self: end;
    width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: $size-XXL;

    @media only screen and (min-width: $bp-extralarge) {
      width: 35rem;
    }

    @media only screen and (max-width: $bp-largest) {
      padding-bottom: $size-XL;
    }

    @media only screen and (max-width: $bp-large) {
      grid-column: col-start 12 / full-end;
    }

    @media only screen and (max-width: $bp-small) {
      // grid-column: 1 / -1;
      // grid-row: widget-start / widget-end;
      // padding-bottom: 0;
      // width: 100%;
      // align-items: center;
      // margin-bottom: 0;
      grid-column: none;
      grid-row: none;
      position: fixed;
      height: max-content;
      width: 100%;
      bottom: 0;
    }
  }
  &__sub-widget {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }
  &__widget-btns {
    width: 100%;
    display: flex;
    margin-right: 0;
  }
  &__replay {
    position: relative;
    width: 100%;
    height: 3.5rem;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    right: 1.5rem;
    color: $color-primary;
    font-size: $font-size-S;
    margin-top: 2rem;

    @media only screen and (max-width: $bp-smallest) {
      justify-content: flex-end;
      position: absolute;
      top: -5rem;
      right: 2.5rem;
      width: 100%;
      height: 2.5rem;
      z-index: 9999;
      box-shadow: none;
      background: none;
      border: none;
    }

    @include transition-time;
    &:hover {
      cursor: pointer;
      filter: drop-shadow(0 1px 4px $color-primary);
      -webkit-filter: drop-shadow(0 1px 4px $color-primary);
    }
  }

  &__replay-icon {
    height: 1.7rem;
    width: 1.6rem;
  }

  &__cta {
    align-self: end;
    grid-column: full-start / col-end 3;
    grid-row: row-start 4 / span 1;
    margin-bottom: $size-XXL;
    @media only screen and (max-width: $bp-largest) {
      padding-bottom: $size-XL;
    }

    @media only screen and (max-width: $bp-small) {
      grid-column: 1 / -1;
      grid-row: cta-start / cta-end;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &__innerpages-bg {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
  &__innerpages-container {
    grid-column: 3 / 21;
    grid-row: header-end / row-end 3;
    padding: $size-XXL;

    @media only screen and (max-width: $bp-largest) {
      grid-column: 4 / 24;
      padding: $size-L;
    }

    @media only screen and (max-width: $bp-large) {
      grid-column: 2 / span 16;
    }

    @media only screen and (max-width: $bp-smallest) {
      grid-column: 1/ -1;
      grid-row: 3/ 5;
      padding: $size-L;
      margin-top: 3rem;
      margin-bottom: -10rem;
    }
  }
}

.widget-btn-name {
  margin-left: 0.8rem;
}

// input:focus ~ .home__widgets {
//   display: none;
//   @media only screen and (max-width: $bp-smallest) {
//     display: none;
//   }
// }

// textarea:focus .home__widgets {
//   @media only screen and (max-width: $bp-smallest) {
//     display: none;
//   }
// }
