.nominee-tag {
  position: absolute;
  z-index: 999999;
  transform: translateY(-50%);

  @media only screen and (max-width: $bp-large) {
    top: 35%;
    right: 0;
  }

  @media only screen and (min-width: $bp-large) {
    top: 45%;
    left: 0;
  }

  @media only screen and (max-width: $bp-tiny) {
    display: none;
  }
}
