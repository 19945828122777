.landscape-screen {
  position: absolute;
  z-index: 99999;
  background-color: $color-black-2;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__icon {
    margin-bottom: $size-XL;
    height: 7.5rem;
    width: 7.5rem;
  }
  &__msg {
    max-width: 20rem;
  }
}
