.subtitle-toggle {
  &__checkbox {
    display: none;
  }
  &__container {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &__text {
    color: $color-primary;
    font-family: $font-headings;
    text-shadow: 0 0 1rem $color-primary, 0 0 2rem $color-primary,
      0 0 3rem $color-primary, 0 0 4rem $color-primary, 0 0 5rem $color-primary,
      0 0 6rem $color-primary, 0 0 7rem $color-primary;
    filter: drop-shadow(0 1px 3px $color-primary);
    -webkit-filter: drop-shadow(0 1px 3px $color-primary);
    @include transition-time;
  }

  &__checkbox:checked + .subtitle-toggle__container .subtitle-toggle__text {
    color: $color-grey-2;
    text-shadow: none;
    filter: none;
    -webkit-filter: none;
  }
}
