.follow-up {
  width: 100%;
  height: 100%;

  &__content {
    padding: $size-M 0 $size-M $size-XL;

    @media only screen and (max-width: $bp-largest) {
      padding: $size-S 0 $size-S $size-L;
    }
  }
  &__heading {
    margin: 0 0 $size-XL $size-M;

    @media only screen and (max-width: $bp-largest) {
      margin: 0 0 $size-L $size-S;
    }
  }
}
