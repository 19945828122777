@font-face {
  font-family: "Enixe";
  src: url("../../fonts/Enixe/font.woff2") format("woff2"),
    url("../../fonts/Enixe/font.woff") format("woff");
}
@font-face {
  font-family: "GrandisExtended-Bold";
  src: url("../../fonts/GrandisExtendedBold/font.woff2") format("woff2"),
    url("../../fonts/GrandisExtendedBold/font.woff") format("woff");
}
@font-face {
  font-family: "Grandis-Regular";
  src: url("../../fonts/GrandisRegular/font.woff2") format("woff2"),
    url("../../fonts/GrandisRegular/font.woff") format("woff");
}
@font-face {
  font-family: "GrandisExtended-Regular";
  src: url("../../fonts/GrandisExtendedRegular/font.woff2") format("woff2"),
    url("../../fonts/GrandisExtendedRegular/font.woff") format("woff");
}
@font-face {
  font-family: "Grandis-Bold";
  src: url("../../fonts/GrandisBold/font.woff2") format("woff2"),
    url("../../fonts/GrandisBold/font.woff") format("woff");
}

body {
  font-family: $font-body;
  font-size: $font-size-XS;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

.heading {
  font-family: $font-headings;
  letter-spacing: -0.06rem;
  &--primary {
    font-size: $font-size-XXL;
  }
  &--secondary {
    font-size: $font-size-XL;
  }
  &--tertiary {
    font-size: $font-size-L;
  }
}

.btn-text {
  font-family: $font-normal;
  font-weight: bold;
  letter-spacing: -0.07rem;

  &--choice {
    position: relative;
    font-size: $font-size-XS;
    margin-left: 1.2rem;
    z-index: 1;
  }
  &--main {
    font-size: $font-size-M;
  }
}

.label-text {
  font-family: $font-headings;
  font-size: $font-size-XS;
}

.subheading {
  font-family: $font-subheading;
  font-size: $font-size-XXXS;
  letter-spacing: 2rem;
  text-transform: uppercase;
}

.section-header {
  grid-column: 1/-1;
  &__heading {
    font-size: $font-size-XXL;
  }
}
