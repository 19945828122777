.glass-normal {
  box-shadow: inset 0 0 6rem rgba($color-white, 0.05),
    inset 0 0 9rem rgba($color-primary, 0.1);
  background: rgba($color-black-1, 0.1);
  border: rgba($color-white, 0.2) 1px solid;

  // Background blur
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

}

.glass-slant.glass-slant-panel {
  width: calc(30rem - 10px);
  height: calc(18rem - 10px);
  padding: 1px;

  @media only screen and (max-width: $bp-largest) {
    width: calc($glass-slant-width - 8px);
    height: calc($glass-slant-height - 8px);
  }

  background: rgba($color-black-1, 0.1);
  background: linear-gradient(
        135deg,
        transparent 10px,
        rgba($color-white, 0.2) 0
      )
      top left,
    linear-gradient(0deg, transparent 0px, rgba($color-white, 0.2) 0) top right,
    linear-gradient(315deg, transparent 10px, rgba($color-white, 0.2) 0) bottom
      right,
    linear-gradient(0deg, transparent 0px, rgba($color-white, 0.2) 0) bottom
      left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  display: inline-block;
}

.glass-slant.glass-slant-panel > .glass-slant-content {
  width: calc(30rem - 1em);
  height: calc(18rem - 1em);

  @media only screen and (max-width: $bp-largest) {
    width: calc($glass-slant-width - 1em);
    height: calc($glass-slant-height - 1em);
  }
  padding: 0.5em;
  background: linear-gradient(135deg, transparent 10px, rgba(0, 0, 0, 0.8) 0)
      top left,
    linear-gradient(0deg, transparent 0px, rgba($color-black, 0.8) 0) top right,
    linear-gradient(315deg, transparent 10px, rgba($color-black, 0.8) 0) bottom
      right,
    linear-gradient(0deg, transparent 0px, rgba($color-black, 0.8) 0) bottom
      left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  display: inline-block;
}
