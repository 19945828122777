.progress-bar {
  display: flex;
  flex-direction: column;
  &__loading {
    overflow: hidden;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $color-grey-2;
    border-bottom: 1px solid $color-grey-2;
  }
  &__percent-container {
    padding: 1px 0;
    min-width: 110%;
    display: flex;
  }
  &__percent-count {
    font-family: $font-headings;
    font-size: $font-size-XXS;
    color: $color-grey-2;
    align-self: flex-end;
  }
  &__percent {
    min-width: 5px;
    height: 12px;
    margin-right: 2px;
    background-color: $color-grey-2;
    transform: skew(-30deg);
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -ms-transform: skew(-30deg);
    -o-transform: skew(-30deg);
  }
}
