.follow-upQ {
  width: 30rem;
  height: 13.8rem;
  background-image: url("../../components/SVGs/followUp-Bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  position: relative;
  @include flexCenter;

  @media only screen and (min-width: $bp-extralarge) {
    height: 16.1rem;
    width: 35rem;
  }

  @media only screen and (max-width: $bp-small) {
    background: none;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding-right: $size-L;
    padding-left: $size-XXL;
    padding-top: $size-S;

    width: 100%;
    height: 100%;

    @media only screen and (max-width: $bp-small) {
      padding-bottom: 1rem;
    }

    @media only screen and (min-width: $bp-extralarge) {
      padding-bottom: 2.5rem;
    }
  }
  &__heading {
    position: relative;
    z-index: 1;
    margin-left: $size-M;
    margin-bottom: $size-XS;
    @media only screen and (max-width: $bp-largest) {
      margin-left: $size-L;
      margin-bottom: $size-S;
    }
  }

  &__bg-big {
    position: fixed;
    width: 29rem;
    height: auto;

    @media only screen and (max-width: $bp-small) {
      display: none;
    }
    @media only screen and (min-width: $bp-extralarge) {
      width: 35rem;
    }
  }
  &__bg-small {
    display: none;

    @media only screen and (max-width: $bp-small) {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
