.separator {
  margin-top: $size-XL;
  margin-bottom: 10rem;
  grid-column: 1/-1;
  opacity: 0.6;
}

.services {
  padding: $size-XL;

  &__description-1 {
    margin-bottom: $size-XXL;
  }

  &__s-1 {
    display: grid;
    grid-template-rows: 4rem 2fr 2fr;
    grid-column: inner-col-start 1 / inner-col-end 4;
    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-end 6;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 4;
    }

    @media only screen and (max-width: $bp-smallest) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }
  &__s-2 {
    display: grid;
    grid-template-rows: 4rem 2fr 2fr;
    grid-column: inner-col-end 4 / inner-col-end 8;
    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-start 9 / inner-col-end 16;
     // margin-bottom: $size-XXXXL;
    }

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 7 / inner-col-end 12;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 5 / inner-col-end 8;
    }
    @media only screen and (max-width: $bp-smallest) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }
  &__s-3 {
    display: grid;
    grid-template-rows: 4rem 2fr 2fr;
    grid-column: inner-col-end 8 / inner-col-end 12;
    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-end 6;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 4;
    }
    @media only screen and (max-width: $bp-smallest) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }
  &__s-4 {
    display: grid;
    grid-template-rows: 4rem 2fr 2fr;
    grid-column: inner-col-end 12 / inner-col-end 16;
    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-start 9 / inner-col-end 16;
    }

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 7 / inner-col-end 12;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 5 / inner-col-end 8;
    }

    @media only screen and (max-width: $bp-smallest) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }

  &__skills {
    grid-column: 1 / -1;
  }
}
