.form-label {
  max-width: 14rem;
  min-width: 9rem;
}

.label {
  position: relative;
  padding-left: 0.4rem;
  margin: 0 0 $size-S $size-XS;
  font-family: $font-headings;
  font-size: $font-size-XS;
  color: $color-black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: $bp-smallest) {
    display: inline-block;
  }

  &__text {
    position: relative;
    z-index: 1;
    margin-left: $size-XXS;
  }
  &__bg {
    position: absolute;
    background: linear-gradient(-135deg, transparent 4px, $color-grey-3 0);
    width: 100%;
    height: $size-S;
    @media only screen and (min-width: $bp-extralarge) {
      height: $size-L;
    }

    &::before {
      position: absolute;
      content: "";
      width: $size-XS;
      height: 100%;
      margin-left: -$size-S;
      background: rgba($color-grey-3, 1);
      @include transition-time;
    }
  }
}
