@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin btnStructure {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: $size-XXXL;
  width: 24rem;
}

@mixin transition-time {
  transition: all 0.3s cubic-bezier(0.55, 0.21, 0.16, 0.84);
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0.21, 0.16, 0.84);
  -moz-transition: all 0.3s cubic-bezier(0.55, 0.21, 0.16, 0.84);
  -ms-transition: all 0.3s cubic-bezier(0.55, 0.21, 0.16, 0.84);
  -o-transition: all 0.3s cubic-bezier(0.55, 0.21, 0.16, 0.84);
}

/*
BUTTON BACKROUND 
*/

@mixin btns($color) {
  background: linear-gradient(
      45deg,
      transparent 5px,
      $color 0,
      transparent 100% 100%
    ),
    linear-gradient(-135deg, transparent 5px, $color 0, transparent 100% 100%);
}

@mixin btn-background-main($color) {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  @include btns($color);
  @include transition-time;
}

@mixin btn-background-hover($color) {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  @include btns($color);
  @include transition-time;
  opacity: 0;
}

@mixin modal-background-active($color) {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  @include btns($color);
  @include transition-time;
}

/*
REVERSED BUTTON BACKROUND 
*/
@mixin btnsReverse($color) {
  background: linear-gradient(
      -45deg,
      transparent 5px,
      $color 0,
      transparent 100% 100%
    ),
    linear-gradient(135deg, transparent 5px, $color 0, transparent 100% 100%);
}

@mixin btnReverse-background-main($color) {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  @include btnsReverse($color);
  @include transition-time;
}

@mixin btnReverse-background-hover($color) {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  @include btnsReverse($color);
  @include transition-time;
  opacity: 0;
}

@mixin fixGlow($color) {
  box-shadow: 0 0 1rem $color, 0 0 2rem $color, 0 0 3rem $color, 0 0 4rem $color,
    0 0 5rem $color, 0 0 6rem $color, 0 0 7rem $color;
  filter: drop-shadow(0 1px 3px $color);
  -webkit-filter: drop-shadow(0 1px 3px $color);
}
