.loading-screen {
  position: absolute;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  @include flexCenter;
  flex-direction: column;
  background-color: $color-black-2;
  &__cta {
    @media only screen and (max-width: $bp-small) {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }

  &__logo {
    position: relative;
    height: 25.5rem;
    width: 15rem;
    @include flexCenter;
    @include transition-time;
    @media only screen and (max-width: $bp-small) {
      height: 17rem;
      width: 10rem;
    }
  }
  &__progress-bar {
    margin: 2rem 0;
    width: 15rem;
    @media only screen and (max-width: $bp-small) {
      width: 10rem;
      margin: 1rem 0;
    }
  }
  &__cookies {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @media only screen and (max-width: $bp-small) {
      align-items: stretch;
      max-width: 12rem;
    }
  }

  &__cookies h3 {
    //@include flexCenter;
    //  margin-bottom: 1rem;

    @media only screen and (max-width: $bp-small) {
      font-size: $size-XS;
      margin-bottom: 0.5rem;
    }
  }
  &__cookies-text {
    margin-bottom: 2rem;
  }

  &__cookies-btns {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $bp-small) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0;
    }

    & :not(:last-child) {
      margin-right: 4rem;
      @media only screen and (max-width: $bp-small) {
        //margin-right: 0.1rem;
      }
    }
  }

  &__disclamer {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    color: $color-primary;
    
    @media only screen and (max-width: $bp-small) {
      position: relative;
      bottom: -5rem;
      max-width: 32rem;
    }
    @media only screen and (max-width: $bp-tiny) {
      max-width: 22rem;
    }
  }

  &__disclamer-text {
    max-width: 24rem;
    @media only screen and (max-width: $bp-small) {
      max-width: 32rem;
    }
  }
}

.logo-loading {
  position: absolute;

  &--black {
    z-index: 18;
    height: 100%;
    width: 100%;
  }
  &--red {
    z-index: 17;
    height: 100%;
    width: 100%;
    top: 5px;
    filter: blur(1px);
    opacity: 0.5;
  }
  &--red-2 {
    z-index: 16;
    height: 100%;
    width: 100%;
    top: 10px;
    filter: blur(8px);
    opacity: 0.4;
    /*prettier-ignore*/
    filter: drop-shadow(0 2px 7px $color-primary);
    /*prettier-ignore*/
    -webkit-filter: drop-shadow(0 2px 7px $color-primary);
    /*prettier-ignore*/
    animation: loadingGlow ease-in-out 1.5s alternate infinite;
    -webkit-animation: loadingGlow ease-in-out 1.5s alternate infinite;
  }
}

.subheading--loading {
  color: $color-grey-2;
  letter-spacing: 1.7rem;
  margin-bottom: 5px;
  @media only screen and (max-width: $bp-small) {
    letter-spacing: 1.22rem;
  }
}
.cookie-check {
  width: 100%;
  align-self: flex-start;
  margin: 0;
}

.updates-msg {
  font-family: $font-headings;
  color: rgb(63, 63, 63);
  margin-bottom: $size-XXL;
  animation: attention 1s infinite;
  -webkit-animation: attention 1s infinite;
}
