.contact {
  padding: $size-XL;
  position: relative;
  z-index: 2;
  &__img {
    grid-column: inner-col-end 2 / inner-col-start 8;
    height: $img-holder-height;
    margin-bottom: $size-M;

    @media only screen and (min-width: $bp-extralarge) {
      grid-column: inner-col-end 2 / inner-col-start 8;
    }

    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-end 1 / inner-col-start 8;
    }

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-start 6;
    }

    @media only screen and (min-width: 767px) and (max-width: 769px) {
      height: 22rem;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-start 4;
      height: 20rem;
    }

    @media only screen and (max-width: $bp-small) {
      height: 12rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      grid-column: inner-col-start 1 / -1;
      height: 32rem;
    }

    @media only screen and (max-width: $bp-tiny) {
      height: 20rem;
    }
  }
  &__desc {
    grid-column: inner-col-end 2 / inner-col-start 8;
    margin-bottom: $size-XXXL;

    // @media only screen and (min-width: 1920px) {
    //   padding-right: 2rem;
    // }

    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-end 1 / inner-col-start 8;
    }

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-start 6;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-start 4;
    }

    @media only screen and (max-width: $bp-smallest) {
      grid-column: inner-col-start 1 / -1;
    }
  }
  &__form {
    grid-column: inner-col-start 8 / inner-col-start 15;
    grid-row: 2 / span 3;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-start 8 / inner-col-start 16;
    }

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 6 / inner-col-end 12;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 4 / inner-col-end 8;
    }

    @media only screen and (max-width: $bp-smallest) {
      grid-column: inner-col-start 1 / -1;
      grid-row: auto;
    }
  }
  &__btns {
    grid-column: inner-col-end 2 / inner-col-start 15;
    display: flex;
    justify-content: space-between;
    margin-bottom: $size-XL;
    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-end 1 / inner-col-start 16;
    }
    @media only screen and (max-width: $bp-largest) {
      grid-column: 1 / -1;
    }
    @media only screen and (max-width: $bp-smallest) {
      flex-direction: column;
      gap: 1.4rem;
    }
  }

  &__progress {
    grid-column: inner-col-end 2 / inner-col-start 15;
    margin-bottom: $size-XXXL;

    @media only screen and (max-width: $bp-largest-plus) {
      grid-column: inner-col-end 1 / inner-col-start 16;
    }
    @media only screen and (max-width: $bp-largest) {
      grid-column: 1 / -1;
    }
  }

  &__feedback-labels {
    width: 100%;
    display: flex;
    //justify-content: space-between;
    justify-content: flex-end;
  }

  &__text-label {
    min-width: 100%;
    margin-bottom: $size-M;
  }
}
// .top-feedback {
//   margin: 0;
//   align-self: flex-end !important;
// }
.email-feedback {
  color: $color-grey-2;
  font-family: $font-subheading;
  font-size: $font-size-XXXS;
  text-transform: uppercase;
}
.email-feedback--important {
  color: $color-primary;
  opacity: 0.8;
  filter: drop-shadow(0 2px 3px $color-primary);
  -webkit-filter: drop-shadow(0 2px 3px $color-primary);
}
