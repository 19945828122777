.btn-label {
  &__checkbox {
    display: none;
  }

  &__container {
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__light {
    //display: inline-block;
    margin-right: 0.4rem;
    height: 2px;
    width: $size-S;
    background: $color-grey-4;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    @include transition-time;
  }

  &__checkbox:checked + .btn-label__container .btn-label__light {
    background: $color-primary;
    box-shadow: 0 0 0.4rem $color-primary, 0 0 2rem $color-primary,
      0 0 3rem $color-primary, 0 0 4rem $color-primary, 0 0 5rem $color-primary,
      0 0 6rem $color-primary, 0 0 7rem $color-primary;
  }
}
