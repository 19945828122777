.track-vertical {
  position: absolute;
  z-index: 55;
  right: 0;
  margin-right: $size-XXS;
  width: 1px;
  height: 100%;
  background-color: $color-grey-3;
  margin-top: $size-L;
  margin-bottom: $size-L;
  @include transition-time;
}

.thumb-vertical {
  position: absolute;
  z-index: 55;
  right: 0;
  margin-right: 2px;
  width: 5px;
  height: 25px;
  background-color: $color-grey-3;
}
