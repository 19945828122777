.rect {
  width: 2px;
  height: 5px;
  background: $color-black-2;
  transform: skew(45deg);
  -webkit-transform: skew(45deg);
  -moz-transform: skew(45deg);
  -ms-transform: skew(45deg);
  -o-transform: skew(45deg);
  @include transition-time;

  &:not(:last-child) {
    margin-right: 2px;
  }
}

/*
MAIN BUTTON CTA
*/

.btn-main {
  position: relative;
  display: grid;
  z-index: 9999;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: $size-XXXL;
  width: 24rem;
  @media only screen and (max-width: $bp-small) {
    grid-template-columns: none;
    grid-template-rows: none;
    position: fixed;
    bottom: 0;

    width: 100%;
    height: $size-XXXL;
  }

  @media only screen and (min-width: $bp-extralarge) {
    height: 5rem;
    width: 30rem;
  }

  transform-origin: bottom left;
  background-color: transparent;
  // @include transition-time;

  &__bg-1 {
    @include btn-background-main($color-primary);
    @media only screen and (max-width: $bp-small) {
      background-color: $color-primary;
    }
  }
  &__bg-2 {
    @include btn-background-hover($color-grey-1);
  }

  /*
  HOVER CHANGES
  */
  &:hover {
    cursor: pointer;
    // width: 45rem;
    // height: 25rem;
  }

  &:hover .btn-main__bg-1 {
    opacity: 0;
  }
  &:hover .btn-main__bg-2 {
    opacity: 1;
  }
  &:hover .btn-main__light {
    //opacity: 0;
    background: $color-primary;
    @include fixGlow($color-primary);
    animation: none;
    -webkit-animation: none;
  }
  // &:hover .btn-main__text {
  //   opacity: 0;
  // }
  &:hover .btn-main__label {
    color: $color-grey-3;
  }
  &:hover .rect {
    background: $color-grey-3;
  }
  // &:hover .btn-main__hireproject {
  //   opacity: 1;
  //   margin-top: $size-S;
  // }
  // &:hover .btn-main__hirestudio {
  //   opacity: 1;
  //   margin-top: $size-S;
  // }

  &:hover .rect {
    animation: opacityOn 0.5s alternate infinite;
    -webkit-animation: opacityOn 0.5s alternate infinite;
  }
  &:hover .rect--1 {
    animation-delay: 0.1s;
  }
  &:hover .rect--2 {
    animation-delay: 0.2s;
  }
  &:hover .rect--3 {
    animation-delay: 0.3s;
  }
  &:hover .rect--4 {
    animation-delay: 0.4s;
  }
  &:hover .rect--5 {
    animation-delay: 0.5s;
  }
  &:hover .rect--6 {
    animation-delay: 0.6s;
  }

  /*
  CHILDREN
  */

  &__hireproject {
    grid-row: 1/3;
    grid-column: 1/2;
    justify-self: stretch;
    display: flex;
    flex-direction: column;
    opacity: 0;
    height: 100%;
    width: 100%;
    padding: $size-L;
    @include transition-time;
  }

  &__hirestudio {
    grid-row: 1/3;
    grid-column: 2/3;

    display: flex;
    flex-direction: column;
    justify-self: stretch;
    opacity: 0;
    height: 100%;
    width: 100%;
    padding: $size-L;
    @include transition-time;
  }

  &__text {
    grid-row: 2/3;
    grid-column: 1/2;
    margin-left: $size-S;
    align-self: center;
    @include transition-time;
  }
  &__label {
    grid-row: 1/2;
    grid-column: 1/2;
    font-family: $font-headings;
    font-size: $font-size-XXXS;
    color: $color-black-2;
    margin-left: 0.4rem;
    margin-top: 0.1rem;
    @include transition-time;
  }
  &__light {
    grid-row: 2/3;
    grid-column: 2/3;
    height: 2px;
    width: $size-S;
    background: $color-white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    justify-self: end;
    align-self: end;
    margin-bottom: 0.8rem;
    margin-right: 0.6rem;
    animation: intenseGlow ease-in-out 0.7s alternate infinite;
    -webkit-animation: intenseGlow ease-in-out 0.7s alternate infinite;
    @include transition-time;
  }
  &__bars {
    grid-row: 1/2;
    grid-column: 2/3;
    justify-self: end;
    margin-right: $size-M;
    display: flex;
    margin-top: 0.3rem;
    @include transition-time;
  }

  &__choice {
    margin: $size-XL 0 0 $size-XL;
    @include transition-time;
  }
}

/*
BUTTON OPEN MODAL SMALL SCREENS
*/
.btn-main-open {
  position: absolute;
  z-index: 999999;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 1fr;
  //

  @media only screen and (min-width: $bp-medium) {
    bottom: 10%;
    left: 4%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 6rem 1fr 1fr;
    width: 50rem;
    height: 32rem;
  }

  &__bg {
    background-color: $color-grey-1;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    @media only screen and (min-width: $bp-medium) {
      @include modal-background-active($color-grey-1);
    }
  }
  &__header {
    grid-row: 1/2;
    grid-column: 1/3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  &__bars {
    // grid-row: 1/2;
    // grid-column: 1/2;
    //justify-self: start;
    // align-self: center;
    margin-right: $size-M;
    display: flex;
    margin-left: 1.4rem;
  }

  &__close-btn {
    // grid-row: 1/2;
    // grid-column: 1/2;
    // justify-self: end;
    // align-self: center;
    // height: 1.8rem;
    // width: 1.8rem;
    // margin-right: 1.4rem;

    @media only screen and (max-width: $bp-smallest) {
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 1.4rem;
    }

    @include transition-time;
    &:hover {
      cursor: pointer;
      filter: drop-shadow(0 1px 3px $color-primary);
      -webkit-filter: drop-shadow(0 1px 3px $color-primary);
    }
    @media only screen and (min-width: $bp-medium) {
      grid-row: 1/2;
      grid-column: 2/2;
      justify-self: end;
      align-self: center;
      height: 1.8rem;
      width: 1.8rem;
      margin-right: 1.4rem;
    }

    @media only screen and (max-width: $bp-medium) {
     height: 1.8rem;
     width: 1.8rem;
     margin-right: 2rem;
    
        }
  }

  &__hireproject {
    grid-column: 1/-1;
    grid-row: 2/3;
    align-self: center;
    padding: $size-XXXL;
    

    &:hover{
      width: 100%;
      height: 100%;
      @include btns($color-primary);
      
      cursor: pointer;
      
    }

    @media only screen and (min-width: $bp-medium) {
      justify-self: center;
      grid-row: 2/4;
      grid-column: 1/2;
    }

    @media only screen and (max-width: $bp-medium) {
      display: flex;
      justify-content: center;
      align-items: center ;
    
        }
  }
  &__hirestudio {
    grid-column: 1/-1;
    grid-row: 3/4;
    align-self: center;
    padding: $size-XXXL;

    @media only screen and (max-width: $bp-medium) {
  display: flex;
  justify-content: center;
  align-items: center ;

    }

    &:hover{
      width: 100%;
      height: 100%;
      @include btns($color-primary);
      
      cursor: pointer;
      
    }

    @media only screen and (min-width: $bp-medium) {
      justify-self: stretch;
      grid-row: 2/4;
      grid-column: 2/3;
    }
  }

  &__content {
    @media only screen and (min-width: $bp-medium) {
      position: relative;
      justify-self: stretch;
      display: flex;
      flex-direction: column;
     // justify-content: center;
      align-items: stretch;
      height: 100%;
      width: 100%;
      //margin-top: 1rem;
      padding: 2rem;
    }
  }
  &__img {
     height: 20rem;
     width: 20rem;

    // width:clamp(20rem, 1.042vw, 30rem); 
    // height:clamp(20rem, 1.042vw, 30rem);




     @media only screen and (max-width: $bp-medium) {
       height: 40rem;
       width: 40rem;
     }





     @media only screen and (max-width: $bp-small) {
     margin: 0;
       height: 25rem;
       width: 25rem;
     }
     @media only screen and (max-width: $bp-tiny) {
       margin: 0;
       height: 20rem;
       width: 20rem;
     }

     @media only screen and (max-width: $bp-smallest) {
      height: 20rem;
      width: 20rem;
    }
  }
  &__choice {
    margin-top: $size-L;
    margin-left: $size-XL;
    position: relative;
   
  }
  &__heading {
    margin-top: $size-XXL;
    margin-bottom: $size-L;
  }
}


// .hire-path{
//   position: absolute;
//   display: flex;
//   align-items: center;
//   color: $color-black-1;
//   width: calc(100% - 1rem);
//   height: $size-XXL;
//   @include transition-time;
//   background: rgba($color-grey-3, 0.6);
//   &__text{
//     margin-left: 1rem;

//   }
//   &::before {
//     position: absolute;
//     content: "";
//     width: 1.5rem;
//     height: 100%;
//     margin-left: -$size-XL;
//     background: rgba($color-grey-3, 0.6);
//   }
// }