.inner-structure {
  position: relative;
  display: grid;
  grid-template-columns:
    [inner-start] repeat(16, [inner-col-start] 1fr [inner-col-end])
    [inner-end];
  column-gap: $size-XL;
  height: 100%;

  @media only screen and (min-width: $bp-extralarge) {
    column-gap: $size-XXL;
  }

  @media only screen and (max-width: $bp-largest) {
    grid-template-columns:
      [inner-start] repeat(12, [inner-col-start] 1fr [inner-col-end])
      [inner-end];
  }

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns:
      [inner-start] repeat(8, [inner-col-start] 1fr [inner-col-end])
      [inner-end];
  }
}
