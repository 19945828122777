.rect {
  width: 2px;
  height: 5px;
  background: $color-grey-3;
  transform: skew(45deg);
  -webkit-transform: skew(45deg);
  -moz-transform: skew(45deg);
  -ms-transform: skew(45deg);
  -o-transform: skew(45deg);
  @include transition-time;
  &:not(:last-child) {
    margin-right: 2px;
  }
}
/*
  STEPS BUTTON CTA
*/
.btn-steps {
  position: relative;
  @media only screen and (max-width: $bp-smallest) {
    width: 100%;
  }
  @include btnStructure;
  @include transition-time;
  &:hover {
    cursor: pointer;
  }
}
/*
  NEXT BUTTON CTA
*/
.btn-next {
  background-color: transparent;

  &__bg-1 {
    @include btn-background-main($color-grey-1);
  }
  &__bg-2 {
    @include btn-background-hover($color-grey-3);
  }
  /*
    NEXT BUTTON HOVER CHANGES
  */

  &:hover .btn-next__bg-1 {
    opacity: 0;
  }
  &:hover .btn-next__bg-2 {
    opacity: 1;
  }
  &:hover .btn-next__text {
    color: $color-grey-1;
  }
  &:hover .btn-next__light {
    animation: none;
    -webkit-animation: none;
  }
  &:hover .rect {
    background: $color-grey-1;
    animation: opacityOn 0.5s alternate infinite;
    -webkit-animation: opacityOn 0.5s alternate infinite;
  }
  &:hover .rect--1 {
    animation-delay: 0.1s;
  }
  &:hover .rect--2 {
    animation-delay: 0.2s;
  }
  &:hover .rect--3 {
    animation-delay: 0.3s;
  }
  &:hover .rect--4 {
    animation-delay: 0.4s;
  }
  &:hover .rect--5 {
    animation-delay: 0.5s;
  }
  &:hover .rect--6 {
    animation-delay: 0.6s;
  }
  /*
    CHILDREN
    */
  &__text {
    grid-row: 2/3;
    grid-column: 2/3;
    margin-right: $size-S;
    justify-self: end;
    @include transition-time;
  }
  &__light {
    grid-row: 1/2;
    grid-column: 1/2;
    @include flexCenter;
    height: $size-S;
    width: $size-S;
    border: 1px solid $color-primary;
    border-radius: 50%;
    background-color: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    align-self: center;
    margin-top: 0.2rem;
    margin-left: 0.4rem;
    animation: glowRed ease-in-out 0.7s alternate infinite;
    -webkit-animation: glowRed ease-in-out 0.7s alternate infinite;
    @include transition-time;
  }
  &__bars {
    grid-row: 1/2;
    grid-column: 2/3;
    justify-self: end;
    margin-right: $size-M;
    display: flex;
    margin-top: 0.3rem;
    @include transition-time;
  }
}
/*
  PREVIOUS BUTTON CTA
*/
.btn-previous {
  background-color: transparent;
  @include transition-time;

  &__bg-1 {
    @include btnReverse-background-main($color-grey-3);
  }
  &__bg-2 {
    @include btnReverse-background-hover($color-grey-4);
  }
  /*
    PREVIOUS BUTTON HOVER CHANGES
  */

  &:hover {
    cursor: pointer;
  }
  &:hover .btn-previous__bg-1 {
    opacity: 0;
  }
  &:hover .btn-previous__bg-2 {
    opacity: 1;
  }
  &:hover .btn-previous__text {
    color: $color-grey-1;
  }
  &:hover .btn-previous__light {
    animation: none;
    -webkit-animation: none;
  }
  &:hover .rect {
    background: $color-grey-1;
  }
  &:hover .rect {
    animation: opacityOn 0.5s alternate infinite;
    -webkit-animation: opacityOn 0.5s alternate infinite;
  }
  &:hover .rect--1 {
    animation-delay: 0.1s;
  }
  &:hover .rect--2 {
    animation-delay: 0.2s;
  }
  &:hover .rect--3 {
    animation-delay: 0.3s;
  }
  &:hover .rect--4 {
    animation-delay: 0.4s;
  }
  &:hover .rect--5 {
    animation-delay: 0.5s;
  }
  &:hover .rect--6 {
    animation-delay: 0.6s;
  }
  /*
    CHILDREN
    */
  &__text {
    grid-row: 2/3;
    grid-column: 1/2;
    margin-left: $size-S;
    align-self: center;
    color: $color-black-1;
    @include transition-time;
  }
  &__light {
    grid-row: 1/2;
    grid-column: 2/3;
    @include flexCenter;
    height: $size-S;
    width: $size-S;
    border: 1px solid $color-primary;
    border-radius: 50%;
    background-color: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    justify-self: end;
    margin-top: 0.2rem;
    margin-right: 0.4rem;
    animation: glowRed ease-in-out 0.7s alternate infinite;
    -webkit-animation: glowRed ease-in-out 0.7s alternate infinite;
    @include transition-time;
  }
  &__arrow {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
  &__bars {
    grid-row: 1/2;
    grid-column: 1/2;
    justify-self: start;
    margin-left: $size-M;
    display: flex;
    margin-top: 0.3rem;
    @include transition-time;
    & .rect {
      background-color: $color-black-1;
      transform: skew(135deg);
      -webkit-transform: skew(135deg);
      -moz-transform: skew(135deg);
      -ms-transform: skew(135deg);
      -o-transform: skew(135deg);
      @include transition-time;
    }
  }
}
