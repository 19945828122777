.skill {
  display: flex;
  align-items: center;

  width: 100%;
  height: clamp(5.8rem, 3.125vw, $size-XXXXL);
  padding: $size-M;
  background: linear-gradient(-135deg, transparent 10px, $color-grey-1 0);

  @media only screen and (max-width: 769px) {
    width: 100%;
  }

  &__content {
    display: flex;
    margin-right: auto;
  }
  &__text {
    & h3 {
      font-size: $font-size-M;
      margin-bottom: -0.5rem;
    }
    & span {
      color: $color-grey-2;
      margin-top: -1rem;
    }
  }
  &__light {
    margin: 0.7rem $size-S 0 0;
    height: $size-S;
    width: 2px;
    background: $color-white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    @include fixGlow($color-white);
  }
}
