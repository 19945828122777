@keyframes glassScreenNormal {
  0% {
    height: 1px;
    width: 1px;
  }
  50% {
    width: 100%;
  }
  100% {
    height: 100%;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 1rem $color-white, 0 0 2rem $color-white,
      0 0 3rem $color-white, 0 0 4rem $color-white, 0 0 5rem $color-white,
      0 0 6rem $color-white, 0 0 7rem $color-white;
  }
  to {
    box-shadow: 0 0 2rem #fff, 0 0 3rem #fff, 0 0 4rem $color-white,
      0 0 5rem $color-white, 0 0 6rem $color-white, 0 0 7rem $color-white,
      $color-white 0 0 8rem $color-white;
  }
}

@keyframes intenseGlow {
  0% {
    box-shadow: 0 0 1rem $color-white, 0 0 2rem $color-white,
      0 0 3rem $color-white, 0 0 4rem $color-white, 0 0 5rem $color-white,
      0 0 6rem $color-white, 0 0 7rem $color-white;
    filter: drop-shadow(0 1px 1px $color-white);
    -webkit-filter: drop-shadow(0 1px 1px $color-white);
  }

  100% {
    box-shadow: 0 0 2rem #fff, 0 0 3rem #fff, 0 0 4rem $color-white,
      0 0 5rem $color-white, 0 0 6rem $color-white, 0 0 7rem $color-white,
      $color-white 0 0 8rem $color-white;
    filter: drop-shadow(0 1px 3px $color-white);
    -webkit-filter: drop-shadow(0 1px 3px $color-white);
  }
}

@keyframes glowRed {
  from {
    box-shadow: inset 0 0 0.4rem $color-primary, 0 0 2rem $color-primary,
      0 0 3rem $color-primary, 0 0 4rem $color-primary, 0 0 5rem $color-primary,
      0 0 6rem $color-primary, 0 0 7rem $color-primary;
  }
  to {
    box-shadow: inset 0 0 0.8rem $color-primary, 0 0 3rem $color-primary,
      0 0 4rem $color-white, 0 0 5rem $color-white, 0 0 6rem $color-white,
      0 0 7rem $color-white, $color-white 0 0 8rem $color-white;
  }
}

@keyframes extend {
  0% {
    height: 2px;
  }
  30% {
    height: 12px;
  }
  60% {
    height: 6px;
  }
  90% {
    height: 12px;
  }
  100% {
    height: 2px;
  }
}

@keyframes opacityOn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadingGlow {
  0% {
    filter: blur(8px);
    opacity: 0.4;
    /* prettier-ignore */
    filter: drop-shadow(0 2px 7px $color-primary);
    /* prettier-ignore */
    -webkit-filter: drop-shadow(0 2px 7px $color-primary);
  }
  100% {
    filter: blur(8px);
    opacity: 0.6;
    filter: drop-shadow(0 10px 8px $color-primary);
    -webkit-filter: drop-shadow(0 10px 8px $color-primary);
  }
}

/*
Choice btn animations
*/

@keyframes choiceBtnHover {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes choiceBtnBeforeHover {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.8;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0.8;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 0.8;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes attention {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
