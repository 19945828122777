.label-desc {
  width: 100%;
  border-bottom: 1px solid $color-grey-3;
  margin-bottom: $size-M;

  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__label {
    width: max-content;
    margin-bottom: -$size-XS;
  }
}
