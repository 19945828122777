.btn-choice {
  display: flex;
  align-items: center;
  position: relative;
  color: $color-grey-4;
  width: 100%;
  height: $size-XXL;

  cursor: pointer;
  margin: 0 0 $size-XS 0;

  @media only screen and (max-width: $bp-smallest) {
    height: $size-XL;
    margin-bottom: $size-M;
  }

  &__bg {
    position: absolute;
 
    width: 100%;
    height: $size-XXL;
    @include transition-time;

    &::before {
      position: absolute;
      content: "";
      width: 1.5rem;
      height: 100%;
      margin-left: -$size-XL;
 
    }
  }
}

.btn-choice:hover .btn-choice__bg {
  background: rgba($color-grey-3, 0.6);
  animation-name: choiceBtnHover;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.55, 0.21, 0.16, 0.84);
}
.btn-choice:hover .btn-choice__bg::before {
  background: rgba($color-grey-3, 0.6);
  animation-name: choiceBtnBeforeHover;
  animation-duration: 0.3s;
  animation-delay: 0.2s;
}

.btn-choice:hover {
  color: $color-black-1;
}

.btn-choice:active .btn-choice__bg {
  background: rgba($color-grey-3, 1);
}

.btn-choice:active .btn-choice__bg::before {
  background: rgba($color-grey-3, 1);
}

.btn-choice--active {
  color: $color-black-1;
}

.btn-choice__bg--active {
  background: rgba($color-grey-3, 0.6);
}
.btn-choice__bg--active::before {
  background: rgba($color-grey-3, 0.6);
}
