.main-structure {
  position: relative;
  display: grid;
  grid-template-columns:
    [margin-left-start] minmax(1rem, 1fr)
    [margin-left-end full-start] repeat(24, [col-start] 1fr [col-end])
    [full-end margin-right-start]
    minmax(1rem, 1fr)
    [margin-right-end];

  grid-template-rows:
    [margin-top-start] 1.5vh
    [margin-top-end header-start] 7vh [header-end] repeat(
      4,
      [row-start] 22.5vh [row-end]
    )
    [margin-bottom-start] 1.5vh [margin-bottom-end];

  column-gap: $size-XL;
  height: 100%;

  @media only screen and (max-width: $bp-large) {
    grid-template-columns:
      [margin-left-start] minmax(1rem, 2rem)
      [margin-left-end full-start] repeat(16, [col-start] 1fr [col-end])
      [full-end margin-right-start]
      minmax(1rem, 2rem)
      [margin-right-end];
  }
  @media only screen and (max-width: $bp-smallest) {
    grid-template-columns:
      [margin-left-start] minmax(0.5rem, 1rem)
      [margin-left-end full-start] repeat(8, [col-start] 1fr [col-end])
      [full-end margin-right-start]
      minmax(0.5rem, 1rem)
      [margin-right-end];

    /* prettier-ignore */
    grid-template-rows: [margin-top-start] 1.5vh [margin-top-end header-start] 7vh [header-end] repeat(2, [row-start] 22.5vh [row-end]) [widget-start] 42.5vh [widget-end cta-start] 4vh [cta-end];

    height: 100vh;
  }
}
