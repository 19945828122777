.sound-toggle {
  display: flex;
  align-items: center;
  &__container {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &__checkbox {
    display: none;
  }
  &__bar {
    width: 2px;
    height: 5px;
    background-color: $color-primary;
    transform-origin: center;
    box-shadow: 0 0 1rem $color-primary, 0 0 2rem $color-primary,
      0 0 3rem $color-primary, 0 0 4rem $color-primary, 0 0 5rem $color-primary,
      0 0 6rem $color-primary, 0 0 7rem $color-primary;
    filter: drop-shadow(0 1px 3px $color-primary);
    -webkit-filter: drop-shadow(0 1px 3px $color-primary);
    @include transition-time;
    &:not(:last-child) {
      margin-right: 2px;

      @media only screen and (max-width: $bp-smallest) {
        width: 2px;
      }
    }
  }

  &__checkbox:checked + .sound-toggle__container .sound-toggle__bar {
    height: 5px;
    background: $color-grey-2;
    box-shadow: 0;
    filter: none;
    -webkit-filter: none;
    animation: none;
    -webkit-animation: none;
  }
}

.bar--1 {
  height: 5px;
  animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 0.8s 0.4s alternate
    infinite;
  -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 0.8s 0.4s
    alternate infinite;
  @include transition-time;
}
.bar--2 {
  height: 8px;
  animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.2s 0.6s alternate
    infinite;
  -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.2s 0.6s
    alternate infinite;
  @include transition-time;
}
.bar--3 {
  height: 3px;
  animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.4s 0.7s alternate
    infinite;
  -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.4s 0.7s
    alternate infinite;
  @include transition-time;
}
.bar--4 {
  height: 10px;
  animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 0.8s 0.4s alternate
    infinite;
  -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 0.8s 0.4s
    alternate infinite;
}
.bar--5 {
  height: 2px;
  animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.2s 0.6s alternate
    infinite;
  -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.2s 0.6s
    alternate infinite;
  @include transition-time;
}
.bar--6 {
  height: 7px;
  animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.4s 0.7s alternate
    infinite;
  -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.4s 0.7s
    alternate infinite;
  @include transition-time;
}
.bar--7 {
  height: 1px;
  animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.2s 0.6s alternate
    infinite;
  -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.2s 0.6s
    alternate infinite;
  @media only screen and (max-width: $bp-smallest) {
    display: none;
  }
  @include transition-time;
}
.bar--8 {
  height: 9px;
  animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.4s 0.7s alternate
    infinite;
  -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.4s 0.7s
    alternate infinite;
  @media only screen and (max-width: $bp-smallest) {
    display: none;
  }
  @include transition-time;
}
