html {
  font-size: 62.5%;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #000000;
  color: $color-grey-3;
}

::-moz-selection {
  color: $color-grey-3;
  background: $color-primary;
}

::selection {
  color: $color-grey-3;
  background: $color-primary;
}

// canvas {
//   width: 100vw;
//   height: 100vh;
// }

#root {
  width: 100vw;
  height: 100vh;
}

/*
HYPR LINKS
*/

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

/*
*/

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  text-align: inherit;
  outline: none;
}

////////////////////////////////////////////////////////////////////////
/*INPUTS*/
////////////////////////////////////////////////////////////////////////
input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  // font-size: 13px;
  // font-family: Arial;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/

/* Button Controls
-----------------------------------------------*/

input[type="checkbox"],
input[type="radio"] {
  width: 13px;
  height: 13px;
}

/* File Uploads
-----------------------------------------------*/

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type="search"] {
  -webkit-appearance: textfield;
  // -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Move the label to the top */
  vertical-align: top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
  &:focus {
    outline: none;
  }
}

/* Selects
-----------------------------------------------*/

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}
