.btn-menuQ {
  width: 18.1rem;
  height: 3.5rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
  background-image: url("../../components/SVGs/QbtnBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  padding-right: 1.5rem;
  padding-bottom: 0.4rem;
  @media only screen and (min-width: $bp-extralarge) {
    height: 4.6rem;
    width: 24.1rem;
    padding-right: 2rem;
    padding-bottom: 0.3rem;
  }

  @media only screen and (max-width: $bp-largest) {
    height: 3.5rem;
    width: 4.1rem;
    background-image: url("../../components/SVGs/QbtnBg-small.svg");
    padding-right: 1.2rem;
    padding-bottom: 0.2rem;
  }

  &__bg-big {
    height: 3.5rem;
    width: 18rem;
    @media only screen and (max-width: $bp-largest) {
      display: none;
    }
    @media only screen and (min-width: $bp-extralarge) {
      height: 4.5rem;
      width: 19rem;
    }
  }
  &__container {
    display: flex;

    @media only screen and (max-width: $bp-largest) {
      @include flexCenter;
      padding: 0;
    }

    @media only screen and (max-width: $bp-smallest) {
      @include flexCenter;
      padding: 0;
    }
  }
  &__content {
    line-height: 1;
    height: 100%;
    width: 100%;

    display: flex;
    justify-items: stretch;
    align-items: center;

    @media only screen and (min-width: $bp-extralarge) {
      @include flexCenter;
      margin-bottom: 0.8rem;
    }
  }

  &__icon {
    margin-left: $size-S;

    width: 2rem;
    height: 2rem;

    @media only screen and (max-width: $bp-largest) {
      width: 1.4rem;
      height: 1.4rem;
      margin-bottom: 0.3rem;
      margin-left: 0;
    }

    @media only screen and (max-width: $bp-smallest) {
      margin-left: 0.3rem;
      margin-bottom: 0.3rem;
    }

    @media only screen and (min-width: $bp-extralarge) {
      width: 2.5rem;
      height: 2.5rem;
      margin-bottom: 0.6rem;
    }

    @include transition-time;
  }
  &__text {
    font-family: $font-headings;
    font-size: $font-size-S;
    padding-top: 0.2rem;

    @media only screen and (max-width: $bp-largest) {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:hover .btn-menuQ__icon {
    filter: drop-shadow(0 1px 4px $color-primary);
    -webkit-filter: drop-shadow(0 1px 4px $color-primary);
  }
}
