.subtitles {
  width: 100%;
  height: 12rem;
  margin-bottom: $size-L;

  @media only screen and (max-width: $bp-smallest) {
    // height: 12rem;
    margin-bottom: 0;
  }
  @media only screen and (min-width: $bp-extralarge) {
    height: 18rem;
    margin-bottom: $size-XL;
    letter-spacing: 1.5;
  }

  &__text {
    padding: $size-XL;
  }
}
