.u-Xlarge-margin-top {
  margin-top: clamp(6rem, 5.208vw, 10rem);
}

.u-heading-margin-bottom {
  margin-bottom: clamp(4rem, 2.083vw, 8rem);
}

.u-display-none {
  display: none;
}

.u-flip-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.u-disabled-btn {
  opacity: 0.5;
  cursor: not-allowed !important;
}
