.image-holder {
  clip-path: polygon(0% 0%, 95% 0%, 100% 5%, 100% 100%, 5% 100%, 0% 95%);
  //Width and height needs to be determined by container so 100% each
  width: 100%;
  height: 100%;
  display: flex;
  &--reverse {
    clip-path: polygon(0% 5%, 5% 0%, 100% 0%, 100% 95%, 95% 100%, 0% 100%);
  }
  // &--dark .image-holder__sidebar {
  //   background: $color-black-2;
  //   color: $color-grey-3;
  // }
  // &--dark .line {
  //   background: $color-white;
  // }

  &__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5%;
    background: $color-grey-3;
    color: $color-black-2;
    font-family: $font-headings;
    font-size: $font-size-XS;
    width: 12%;
    height: 100%;
  }
  &__sidebar-text {
    width: 15rem;
    // transform-origin: top;
    margin-bottom: 7rem;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
  }
  &__sidebar-lines {
    display: flex;
    flex-direction: row;
  }
}

.line {
  width: 2px;
  background: $color-black-2;

  &:not(:last-child) {
    margin-right: 2px;
  }
  &-1 {
    height: 5px;
    animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 0.8s 0.4s alternate
      infinite;
    -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 0.8s 0.4s
      alternate infinite;
  }
  &-2 {
    height: 8px;
    animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.2s 0.6s alternate
      infinite;
    -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.2s 0.6s
      alternate infinite;
  }
  &-3 {
    height: 3px;
    animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.4s 0.7s alternate
      infinite;
    -webkit-animation: extend cubic-bezier(0.55, 0.21, 0.16, 0.84) 1.4s 0.7s
      alternate infinite;
  }
}
