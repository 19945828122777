.autofit-structure {
  position: relative;
  display: grid;
  grid-template-columns:
    [inner-auto-start] repeat(
      auto-fit,
      [inner-auto-col-start] minmax(25rem, 1fr) [inner-auto-col-end]
    )
    [inner-auto-end];
  column-gap: $size-XL;
  row-gap: $size-XL;

  @media only screen and (min-width: $bp-extralarge) {
    column-gap: $size-XXXXL;
    row-gap: $size-XXXL;
  }

  @media only screen and (max-width: $bp-largest-plus) {
    grid-template-columns:
      [inner-auto-start] repeat(
        auto-fit,
        [inner-auto-col-start] minmax(24rem, 1fr) [inner-auto-col-end]
      )
      [inner-auto-end];

    column-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  @media only screen and (max-width: 769px) {
    align-content: center;

    column-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    row-gap: 2rem;
  }
}
