// .canvas {
//   position: fixed;
// }

.canvas {
  position: fixed;
  z-index: -5;
  top: 0;
  width: inherit;
  height: inherit;
}
