.header {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3.5rem;
  grid-column: full-start/full-end;
  grid-row: header-start/header-end;

  &__logo {
    position: absolute;
    left: 50%;
    width: 2.4rem;
    @media only screen and (max-width: $bp-smallest) {
      width: $size-XL;
    }
    @media only screen and (min-width: $bp-extralarge) {
      width: $size-XXL;
    }
    height: auto;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }

  &__toggels {
    justify-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__sub-toggle {
    margin-right: $size-XXXL;
    @media only screen and (max-width: $bp-smallest) {
      margin-right: $size-XXL;
    }
  }
}
