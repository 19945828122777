/*
BREAK POINTS VARIABLES
*/

$bp-extralarge: 120.0625em; // 1921px/16
$bp-largest-plus: 93.75em; //1500px/16
$bp-largest: 75em; // 1200px/16
$bp-large: 68.75em; // 1100px/16
$bp-medium: 56.25em; // 900px/16
$bp-small: 37.5em; // 600px/16
$bp-smallest: 31.25em; // 500px/16
$bp-tiny: 18.75em; // 300px/16

/*
COLOR VARTIABLES
*/

$color-primary: #a61400;

$color-black-1: #030303;
$color-black-2: #0c0c0c;

$color-grey-1: #131313;
$color-grey-2: #4f4f4f;
$color-grey-3: #dbdbdb;
$color-grey-4: #888;

$color-white: #fff;
$color-black: #000;

/*
TYPOGRAPHY AND SIZES VARTIABLES
*/

$font-body: "GrandisExtended-Regular", sans-serif;
$font-headings: "Enixe", sans-serif;
$font-normal: "Grandis-Bold", sans-serif;
$font-subheading: "GrandisExtended-Bold", sans-serif;

$font-size-XXXS: clamp(0.4rem, 0.417vw, 1rem); //0.8rem
$font-size-XXS: clamp(0.8rem, 0.521vw, 1rem); //1rem
$font-size-XS: clamp(1rem, 0.625vw, 1.6rem); //1.2rem
$font-size-S: clamp(1.2rem, 0.833vw, 1.8rem); //1.6rem
$font-size-M: clamp(1.6rem, 0.938vw, 2rem); //1.8rem
$font-size-L: clamp(1.6rem, 0.938vw, 2.1rem); //2rem
$font-size-XL: clamp(1.6rem, 1.094vw, 3.2rem); //2.1rem
$font-size-XXL: clamp(1.6rem, 1.667vw, 3.5rem); //3.2rem

/*
MEASURMENTS AND GAPS VARIABLES
*/

$size-XXS: 0.5rem;
$size-XS: 0.8rem;
$size-S: 1.2rem;
$size-M: 1.4rem; //1.4rem
$size-L: 1.6rem;
$size-XL: 2rem;
$size-XXL: 3.5rem;
$size-XXXL: 4rem;
$size-XXXXL: 6rem;

$glass-slant-width: clamp(25rem, 15.625vw, 3.2rem);
$glass-slant-height: clamp(17rem, 9.375vw, 20rem);

$img-holder-height: clamp(30rem, 15.625vw, 75rem);
$img-holder-height-big: clamp(30rem, 26.042vw, 75rem);
