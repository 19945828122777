.main-menu {
  grid-column: col-start 21 / margin-right-start;
  grid-row: 1/-1;
  display: flex;
  flex-direction: column;
  //padding-top: 12rem;
  padding-top: clamp(12rem, 6.25vw, 35rem);

  @media only screen and (min-width: $bp-extralarge) {
    grid-column: col-start 21 / margin-right-start;
  }

  @media only screen and (max-width: $bp-large) {
    grid-column: col-start 12 / margin-right-start;
  }

  @media only screen and (max-width: $bp-large) {
    grid-column: 2 / -2;
  }


  &__heading {
    position: relative;
    z-index: 20;
    margin-left: $size-S;
    margin-bottom: $size-L;
  }

  &__sentinel {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: $size-XL;
  }

  &__cecraft {
    display: flex;
    flex-direction: column;
  }
  &__bg {
    position: relative;
    z-index: -1;
    grid-column: col-start 20 / margin-right-end;
    grid-row: 1/-1;
    @media only screen and (min-width: $bp-extralarge) {
      grid-column: col-start 20 / margin-right-end;
    }
    @media only screen and (max-width: $bp-large) {
      grid-column: col-start 11 / margin-right-end;
    }

    @media only screen and (max-width: $bp-large) {
      grid-column: 1 / -1;
    }
  }
}
