.about {
  padding: $size-XL;
  &__heading {
    grid-column: inner-col-start 1 / inner-col-end 16;
    margin-bottom: $size-XXL;
    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }

  &__image--1 {
    height: $img-holder-height;
    grid-column: inner-col-start 1 / inner-col-end 6;

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-end 4;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 4;
    }

    @media only screen and (max-width: $bp-small) {
      height: 20rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      height: 13rem;
    }

    @media only screen and (max-width: $bp-tiny) {
      grid-column: 1 / -1;
      height: 20rem;
      margin-bottom: 1rem;
    }
  }
  &__image--2 {
    height: $img-holder-height;
    grid-column: inner-col-start 7 / inner-col-end 12;

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 5 / inner-col-end 8;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 5 / inner-col-end 8;
      margin-bottom: $size-M;
    }
    @media only screen and (max-width: $bp-small) {
      height: 20rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      height: 13rem;
    }

    @media only screen and (max-width: $bp-tiny) {
      grid-column: 1 / -1;
      height: 20rem;
    }
  }
  &__details {
    grid-column: inner-col-end 12 / inner-col-end 16;
    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-end 8 / inner-col-end 12;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }

  &__recognition {
    grid-column: inner-col-end 12 / inner-col-end 16;
    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-end 8 / inner-col-end 12;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
    margin-bottom: $size-XL;
  }

  &__workstyle {
    grid-column: inner-col-start 1 / inner-col-end 16;

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-end 12;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }
  &__work-style-label {
    grid-column: inner-col-start 1 / inner-col-end 12;
    margin-top: $size-L;
    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-end 12;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }
  .work-style--1 {
    grid-column: inner-col-start 1 / inner-col-end 4;

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }

  .work-style--2 {
    grid-column: inner-col-end 4 / inner-col-end 8;

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }

  .work-style--3 {
    grid-column: inner-col-end 8 / inner-col-end 12;
    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
    margin-bottom: $size-XXXXL;
  }

  &__extra-content-1 {
    grid-column: inner-col-start 1 / inner-col-end 8;
    margin-bottom: 5rem;

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-end 6;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
    }
  }
  &__extra-image-1 {
    grid-column: inner-col-start 1 / inner-col-end 8;
    margin-bottom: $size-M;
    height: $img-holder-height-big;

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-start 1 / inner-col-end 6;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
      height: 55rem;
    }

    @media only screen and (max-width: $bp-small) {
      grid-column: inner-col-start 1 / inner-col-end 8;
      height: 40rem;

      grid-row: auto;
    }

    @media only screen and (max-width: $bp-smallest) {
      height: 30rem;
    }

    @media only screen and (max-width: $bp-tiny) {
      height: 20rem;
    }
  }

  &__extra-content-2 {
    grid-column: inner-col-start 9 / inner-col-end 16;
    grid-row: 7 / span 1;
    margin-bottom: 5rem;
    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-end 6 / inner-col-end 12;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
      grid-row: auto;
    }
  }
  &__extra-image-2 {
    grid-row: 6 / span 1;
    grid-column: inner-col-start 9 / inner-col-end 16;
    margin-bottom: $size-M;
    height: $img-holder-height-big;

    @media only screen and (max-width: $bp-largest) {
      grid-column: inner-col-end 6 / inner-col-end 12;
    }

    @media only screen and (max-width: $bp-medium) {
      grid-column: inner-col-start 1 / inner-col-end 8;
      height: 55rem;

      grid-row: auto;
    }

    @media only screen and (max-width: $bp-small) {
      grid-column: inner-col-start 1 / inner-col-end 8;
      height: 40rem;

      grid-row: auto;
    }

    @media only screen and (max-width: $bp-smallest) {
      height: 30rem;
    }

    @media only screen and (max-width: $bp-tiny) {
      height: 20rem;
    }
  }

  &__media-link {
    @include transition-time;
  }
  &__media-link:not(:last-child) {
    margin-right: 1rem;
  }
  &__media-link:hover {
    filter: drop-shadow(0 1px 4px $color-primary);
    -webkit-filter: drop-shadow(0 1px 4px $color-primary);
  }
}

.link {
  text-decoration: underline;
  @include transition-time;
  &:hover {
    color: $color-primary;
    filter: drop-shadow(0 1px 4px $color-primary);
    -webkit-filter: drop-shadow(0 1px 4px $color-primary);
  }
}
