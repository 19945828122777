.scroll-component {
  position: relative;
  // CHANGE TO 100% EACH
  width: 100%;
  height: 100%;

  &__shadow-top {
    position: absolute;
    opacity: 0.5;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 4rem;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba($color-black-1, 0) 100%
    );

    @include transition-time;
  }
  &__shadow-bottom {
    position: absolute;
    z-index: 1;
    opacity: 0.5;
    bottom: 0;
    width: 100%;
    height: 4rem;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba($color-black-1, 0) 100%
    );

    @include transition-time;
  }
  &__content {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    @include transition-time;
  }
}
