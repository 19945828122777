/*
  PREVIOUS BUTTON CTA
*/

.btn-send {
  background-color: transparent;
  @include transition-time;

  &__bg-1 {
    @include btnReverse-background-main($color-grey-1);
  }
  &__bg-2 {
    @include btnReverse-background-hover($color-grey-3);
  }
  /*
      PREVIOUS BUTTON HOVER CHANGES
    */

  &:hover {
    cursor: pointer;
  }
  &:hover .btn-send__bg-1 {
    opacity: 0;
  }
  &:hover .btn-send__bg-2 {
    opacity: 1;
  }
  &:hover .btn-send__text {
    color: $color-grey-1;
  }
  &:hover .btn-send__light {
    animation: none;
    -webkit-animation: none;
  }
  &:hover .rect {
    background: $color-grey-1;
  }
  &:hover .rect {
    animation: opacityOn 0.5s alternate infinite;
    -webkit-animation: opacityOn 0.5s alternate infinite;
  }
  &:hover .rect--1 {
    animation-delay: 0.1s;
  }
  &:hover .rect--2 {
    animation-delay: 0.2s;
  }
  &:hover .rect--3 {
    animation-delay: 0.3s;
  }
  &:hover .rect--4 {
    animation-delay: 0.4s;
  }
  &:hover .rect--5 {
    animation-delay: 0.5s;
  }
  &:hover .rect--6 {
    animation-delay: 0.6s;
  }
  /*
      CHILDREN
      */
  &__text {
    grid-row: 1/3;
    grid-column: 1/2;
    margin-left: $size-S;
    justify-self: start;
    align-self: end;
    color: $color-grey-3;
    @include transition-time;
  }
  &__light {
    grid-row: 1/2;
    grid-column: 2/3;
    justify-self: end;
    margin-top: 0.2rem;
    margin-right: 0.2rem;
    @include transition-time;
  }

  &__bars {
    grid-row: 1/2;
    grid-column: 1/2;
    justify-self: start;
    margin-left: $size-M;
    display: flex;
    margin-top: 0.3rem;
    @include transition-time;
    & .rect {
      background-color: $color-grey-3;
      transform: skew(135deg);
      -webkit-transform: skew(135deg);
      -moz-transform: skew(135deg);
      -ms-transform: skew(135deg);
      -o-transform: skew(135deg);
      @include transition-time;
    }
  }
}
